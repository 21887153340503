<template>
  <div class="container">
    <img
      class="hidden-sm-and-down"
      style="width: 100%"
      src="../../../assets/mock/us/组 7473.png"
      alt=""
    />
    <img
      class="hidden-md-and-up"
      style="width: 100%"
      src="../../../assets/mock/us/组 11467.png"
      alt=""
    />
    <div>
      <div class="top">
        <h3>联系我们</h3>
        <span></span>
        <p>CONTACT US</p>
      </div>
      <!-- contentHeader -->
      <div class="contentHeader warp">
        <div style="margin: 1.875rem 0">
          
          <p>
            宇起数字科技（上海）有限公司，始创于2017年，宇起专注于IOT物联网平台、智能硬件、智能家居等领域。“宇你一起，享受智能生活”是宇起数字一直铭记于心的理念，始终以艺术品的审美标准，以用户为中心，以不断创新的精神，研发各类智能指纹产品及应用，提供安全便捷的用户体验。
          </p>
          <p>
            宇起数字力求将智能设备开发与场景化应用密切结合，在保证用户隐私安全的基础上，同时不断优化用户体验，提升产品的品质，我们一直相信精品永远属于那些将技术、便捷、简易、美观做到极致的产品。宇起数字智能设备已逐步深入到智能家居、智慧办公、智慧楼宇、智慧校园、智慧社区、智慧物业、智慧医疗等多个板块。宇起数字已研发出智能密码器、智能指纹箱包锁、智能指纹办公抽屉柜锁、智能指纹锁等系列产品。
          </p>
          <p>
            宇起智能设备是由APP+智能设备+控制终端组成的物联网智能设备。通过云端管理，实现在PC端、移动端、远程和现场操控智能设备和场景的切换。作为少数具备智能设备一体化自主研发实力技术、自主掌握指纹核心算法的驱动型企业，宇起数字拥有十余项技术专利，安全技术、无线网技术、移动通讯等物联网技术，实现硬件与软件、系统平台与手机客户端APP应用集成，组建智能物联网平台。
          </p>
        </div>
        <div class="banners">
          <img
            style="width: 100%"
            src="../../../assets/mock/us/矩形 2515.png"
            alt=""
          />
        </div>
        <ul>
          <li>
            <img src="../../../assets/mock/us/组 1144.png" alt="" /><br />
            <span>高端品质</span>
          </li>
          <li>
            <img src="../../../assets/mock/us/组 1143.png" alt="" /><br />
            <span>智能物联</span>
          </li>
          <li>
            <img src="../../../assets/mock/us/组 1142.png" alt="" /><br />
            <span>创新科技</span>
          </li>
          <li>
            <img src="../../../assets/mock/us/组 1141.png" alt="" /><br />
            <span>隐私安全</span>
          </li>
        </ul>
      </div>
      <!-- us -->
      <div class="top">
        <h3>公司文化</h3>
        <span></span>
        <p>COMPANY CULTURE</p>
      </div>
      <!-- COMPANY CULTURE -->
      <div class="warp culture">
        <ul>
          <li style="background-color: #fff8f2">
            <img src="../../../assets/mock/us/矩形 2829.png" alt="" />
            <div>
              <h5><span>01</span>品牌愿景</h5>
              <p>Brand Vision</p>
              <p>成为国际领先的物联网行业综合服务商。</p>
            </div>
          </li>
          <li style="background-color: #f0faff">
            <img src="../../../assets/mock/us/蒙版组 12.png" alt="" />
            <div>
              <h5><span>02</span>品牌理念</h5>
              <p>Brand concept</p>
              <p>宇你一起、享受智能生活。</p>
            </div>
          </li>
          <li style="background-color: #fff8f2">
            <img src="../../../assets/mock/us/蒙版组 13.png" alt="" />
            <div>
              <h5><span>03</span>品牌愿景</h5>
              <p>Brand connotation</p>
              <p>保持产品创新，不断以技术领跑，提高产品换代能力。</p>
            </div>
          </li>
          <li style="background-color: #f0faff">
            <img src="../../../assets/mock/us/蒙版组 14.png" alt="" />
            <div>
              <h5><span>04</span>品牌愿景</h5>
              <p>Brand Vision</p>
              <p>数字科技的简约美。</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  .top {
    text-align: center;
    h3 {
      margin-top: 1.25rem;
    }
    span {
      width: 1.25rem;
      height: 3px;
      display: inline-block;
      background-color: #3cb59e;
    }
  }
  .contentHeader {
    p {
      text-indent: 2rem;
      font-size: 0.875rem;
      line-height: 2;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 1.875rem auto;
      li {
        text-align: center;
        img {
          margin-bottom: 0.625rem;
        }
      }
    }
  }
  .culture {
    overflow: hidden;
    margin-bottom: 3.125rem;
    ul {
      display: flex;
      flex-flow: wrap;
      justify-content: space-around;
      li {
        width: 17rem;
        margin-bottom: 1.25rem;
        img {
          width: 100%;
        }
        overflow: hidden;
        text-align: center;
        p {
          margin: 0;
          font-size: 0.75rem;
          padding: 5px 0;
          color: #777777;
        }
        span {
          font-size: 21px;
          font-family: Gotham-Bold;
          line-height: 25px;
          color: #3cb59e;
        }
      }
    }
  }
}
</style>
